.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  inset: 0;
  height: 250px;
  z-index: 50;
  outline: 2px solid transparent;
  outline-offset: 2px;
  background-color: transparent;
}
.modal-border {
  background-color: rgb(163, 210, 241);
  width: 50%;
}
.modal-header {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.modal-bottom {
  display: flex;
  justify-content: flex-end;
}
.modal-bigger {
  opacity: 0.25;
  position: fixed;
  inset: 0;
  z-index: 40;
  background: #000;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.stay-button {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 4px;
  background-color: aquamarine;
  cursor: pointer;
}
.stay-button:hover {
  background-color: aliceblue;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
