.inputs {
  width: 100%;
  padding: 12px 20px;
}
input[type="email"],
input[type="password"] {
  padding: 12px 20px;
}

.bttn {
  background-color: #04aa6d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 20%;
}
.expenses {
  padding: 1rem;
  margin: 2rem auto;
  width: 30rem;
  background-color: aliceblue;
}
.bgimage {
  background-color: #04aa6d;
}
