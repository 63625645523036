table, td, th{
  border: 1px solid forestgreen;
  
}

table{
  border-collapse: collapse;
  width:80%;
  height:auto; 
  margin:auto;
}

th {
  width: 25%;
  border-bottom: 1px solid black;
  padding: 20px;
}

td {
  white-space: nowrap;
  height: 30px;
  text-align: left;
  padding: 5px; 
}
td:nth-child(6){
  height: 30px;
  text-align: center;
}
td:nth-child(1){
  height: 30px;
  text-align: center;
}

.button {
  width: fit-content;
  margin: 2px;
  cursor: pointer;
  border-radius: 5px;
   /* overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.button:hover {
  border-color: green;
  background-color: #c3c9c3;
}

.btn {
  margin: 3%;
  text-decoration: none;
  background-color: forestgreen;
  color: white;
  border-radius: 4px;
  padding: 1rem 3rem;
  border: 1px solid teal;
  cursor: pointer;
}

.btn:hover,
.btn:active {
  background-color: #11acac;
  border-color: #11acac;
}
